import { config } from '@app/config';
import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';
import { ModeEnvs } from '@app/constants/chains';

export function getSymbol(symbol: string) {
  switch (symbol.toLowerCase()) {
    case 'weth': {
      return config.modeEnv === ModeEnvs.TESTNET ? 'WtCORE' : 'WCORE';
    }
    default: {
      return symbol;
    }
  }
}

export function getTokenAddress(address: string) {
  switch (address.toLowerCase()) {
    case config.contracts.weth!.toLowerCase(): {
      return NATIVE_TOKEN_ADDRESS;
    }
    default: {
      return address;
    }
  }
}
