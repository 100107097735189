import {
  Box,
  Button,
  Center,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

import { Icon } from '@app/components/Icon';
import { useChainExplorer } from '@app/hooks/other/useExplorerAddress';

import s from './TransactionResultModal.module.scss';

interface Props {
  status?: 'success' | 'error';
  title?: string;
  message?: string;
  isOpen: boolean;
  onClose: () => void;
  txHash?: string;
}

export const TransactionResultModal: FC<Props> = ({
  isOpen,
  onClose,
  status,
  title,
  message,
  txHash
}) => {
  const router = useRouter();

  const { explorer } = useChainExplorer();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      isCentered
      motionPreset="slideInBottom"
      size="xs"
    >
      <ModalOverlay />
      <ModalContent
        sx={{
          maxWidth: ['100vw', '328px'],
          padding: '16px',
          background: 'gray.5',
          boxShadow: '0px 8px 24px 6px rgba(18, 20, 18, 0.80);'
        }}
      >
        <VStack w="100%" gap={0}>
          <Center w="88px" h="88px" borderRadius="50%">
            <Icon
              name={status === 'success' ? 'check' : 'error'}
              style={{ width: 88, height: 88 }}
            />
          </Center>
          <Box mt="12px" w="100%">
            <Text w="100%" textAlign="center" className={s.message}>
              {message}
            </Text>
          </Box>
        </VStack>
        <ModalFooter gap="16px" mt="28px" flexDirection="column">
          <Button
            w="100%"
            variant="primary"
            onClick={async () => {
              onClose();
            }}
          >
            {status === 'success' ? 'Done' : 'Go back'}
          </Button>
          <Button
            w="100%"
            variant="secondary"
            onClick={async () => {
              if (txHash) {
                window.open(`${explorer?.url}/tx/${txHash}`);
              } else {
                router.push('/positions');
              }
            }}
          >
            {txHash ? 'View on explorer' : 'Your positions'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
