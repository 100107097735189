import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';
import {
  CurrencyAmount,
  Token,
  NativeCurrency,
  Currency
} from '@uniswap/sdk-core';
import { Pair, Trade } from '@uniswap/v2-sdk';
import { useMemo } from 'react';

import { config } from '@app/config';
import { TOKENS_WHITELIST } from '@app/constants/tokensWhitelist';
import { numberToBigNumberFixed } from '@app/helpers/format';
import { useAllPairs } from '@app/hooks/pair/useAllPairs';
import { Pair as KPair } from '@app/types/pool';
import { Token as KToken } from '@app/types/token';
import { getActiveChain } from '@app/constants/chains';

const activeChain = getActiveChain();

const WETH = TOKENS_WHITELIST.find(
  item => item.contractAddress === config.contracts.weth
)!;

export class ModeEther extends NativeCurrency {
  readonly address = WETH.contractAddress;
  constructor() {
    super(activeChain.chainId, WETH.decimals);
  }

  get wrapped(): Token {
    return new Token(
      activeChain.chainId,
      config.contracts.weth!,
      WETH.decimals,
      WETH.symbol
    );
  }

  sortsBefore(other: Token): boolean {
    return this.address.toLowerCase() < other.address.toLowerCase();
  }

  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId;
  }
}

function getToken(token: KToken) {
  return token.contractAddress === NATIVE_TOKEN_ADDRESS
    ? new ModeEther()
    : new Token(
        activeChain.chainId,
        token.contractAddress,
        +token.decimals,
        token.symbol
      );
}

function getCurrencyAmount(token: KToken, amount: string) {
  const _token =
    token.contractAddress === NATIVE_TOKEN_ADDRESS
      ? new ModeEther()
      : new Token(
          activeChain.chainId,
          token.contractAddress,
          +token.decimals,
          token.symbol
        );

  return CurrencyAmount.fromRawAmount(
    _token,
    numberToBigNumberFixed(+amount, token.decimals).toString()
  );
}

function gcd(a: number, b: number): number {
  return b ? gcd(b, a % b) : a;
}

function decimalToFraction(_decimal: number) {
  if (_decimal % 1 == 0) {
    return {
      top: _decimal,
      bottom: 1,
      display: _decimal + ':' + 1
    };
  } else {
    let top: string | number = _decimal.toString().replace(/\d+[.]/, '');
    let bottom = Math.pow(10, top.length);

    if (_decimal > 1) {
      top = +top + Math.floor(_decimal) * bottom;
    }

    let x = gcd(+top, bottom);

    return {
      top: +top / x,
      bottom: bottom / x,
      display: +top / x + ':' + bottom / x
    };
  }
}

function getPair(pair: KPair) {
  const { token0, token1, reserve0, reserve1 } = pair;
  const tokenA = new Token(
    activeChain.chainId,
    token0.id,
    +token0.decimals,
    token0.symbol
  );

  const currencyAmountA = CurrencyAmount.fromRawAmount(
    tokenA,
    numberToBigNumberFixed(+reserve0, +token0.decimals).toString()
  );

  const tokenB = new Token(
    activeChain.chainId,
    token1.id,
    +token1.decimals,
    token1.symbol
  );

  const currencyAmountB = CurrencyAmount.fromRawAmount(
    tokenB,
    numberToBigNumberFixed(+reserve1, +token1.decimals).toString()
  );

  return new Pair(currencyAmountA, currencyAmountB);
}

export function useTrade(
  from: { token: KToken | null; amount: string },
  to: { token: KToken | null; amount: string }
) {
  const { data: pairs, refetch } = useAllPairs();

  const trades = useMemo(() => {
    if (!pairs?.length || !from.token || !to.token) {
      return;
    }

    if (
      from.token.contractAddress === config.contracts.weth &&
      to.token.contractAddress === NATIVE_TOKEN_ADDRESS
    ) {
      return;
    }

    if (
      to.token.contractAddress === config.contracts.weth &&
      from.token.contractAddress === NATIVE_TOKEN_ADDRESS
    ) {
      return;
    }

    const _pairs = pairs.map(pair => getPair(pair));

    const input = from.token;
    const output = to.token;

    if (!input || !output) {
      return;
    }

    return Trade.bestTradeExactIn(
      _pairs,
      getCurrencyAmount(input, from.amount),
      getToken(output)
    );
  }, [from.amount, from.token, pairs, to.token]);

  return {
    trades,
    refetch
  };
}
