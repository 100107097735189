import { Input } from '@chakra-ui/react';
import fromExponential from 'from-exponential';
import { debounce } from 'lodash';
import React, {
  FC,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import s from './DebouncedInput.module.scss';
import clsx from 'clsx';

interface Props {
  amount: string;
  onChange?: (val: string) => void;
  readOnlyValue?: string;
  disabled?: boolean;
  textAlign?: 'left' | 'right';
  placeholder?: string;
  type?: 'text' | 'number';
  isError?: boolean;
}

const useDebounce = (callback: () => void) => {
  /* eslint-disable */
  // @ts-ignore
  const ref = useRef<any>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  return useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, 700);
  }, []);
};

export const DebouncedInput: FC<Props> = ({
  amount,
  onChange,
  disabled,
  readOnlyValue,
  textAlign = 'right',
  placeholder,
  type = 'number',
  isError
}) => {
  const [value, setValue] = useState(amount);

  const onChangeHandler = () => {
    onChange?.(value);
  };

  const debouncedOnChange = useDebounce(onChangeHandler);

  const handleSearchKeyup = useCallback<KeyboardEventHandler>(
    e => {
      if (e.key === 'Enter') {
        debouncedOnChange.flush();
      } else if (e.key === 'Escape') {
        debouncedOnChange.cancel();
        setValue('');
        onChange?.('');
      }
    },
    [debouncedOnChange]
  );

  useEffect(() => {
    if (value !== amount) {
      setValue(amount);
    }
  }, [amount]);

  return (
    <Input
      variant="filled"
      borderRadius={4}
      w="100%"
      px={0}
      textAlign={textAlign}
      type={type ?? 'number'}
      formNoValidate
      lang="en"
      step="any"
      min={0}
      value={
        readOnlyValue !== undefined
          ? fromExponential(Number(readOnlyValue))
          : value
      }
      onKeyUp={handleSearchKeyup}
      onChange={e => {
        debouncedOnChange();
        setValue(e.target.value);
      }}
      disabled={disabled || !onChange}
      placeholder={placeholder ?? '0.00'}
      _placeholder={{ color: 'neutral.400' }}
      className={clsx(s.input, {
        [s.error]: isError
      })}
    />
  );
};
