import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { request, gql } from 'graphql-request';

import { QueryKeys } from 'src/constants/queryKeys';

import { config } from '@app/config';
import { Pair } from '@app/types/pool';

const fetcher = () => {
  return request<{ pairs: Pair[] }>(
    `${config.subgraphUrl}`,
    gql`
      query {
        pairs {
          id
          token0 {
            id
            totalSupply
            symbol
            name
            decimals
            tradeVolumeUSD
            tradeVolume
            totalLiquidity
            txCount
            derivedETH
          }
          token1 {
            id
            totalSupply
            symbol
            name
            decimals
            tradeVolumeUSD
            tradeVolume
            totalLiquidity
            txCount
            derivedETH
          }
          reserve0
          reserve1
          reserveETH
          reserveUSD
          token0Price
          token1Price
          volumeUSD
        }
      }
    `
  );
};

export const useAllPairs = (options: UseQueryOptions<Pair[]> = {}) => {
  return useQuery<Pair[]>(
    [QueryKeys.ALL_PAIRS],
    async () => {
      const response = await fetcher();

      return response.pairs;
    },
    {
      staleTime: 60000,
      ...options
    }
  );
};
