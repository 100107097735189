import { useContract } from '@thirdweb-dev/react';
import { TradeType } from '@uniswap/sdk-core';
import { Token as UNSToken } from '@uniswap/sdk-core/dist/entities/token';
import { Trade } from '@uniswap/v2-sdk';
import fromExponential from 'from-exponential';
import { useEffect } from 'react';
import { useAsync, useAsyncFn, useMount } from 'react-use';

import { config } from '@app/config';
import { isEthWethSwap } from '@app/hooks/swap/helpers';
import { Token } from '@app/types/token';
import { ModeEther } from '@app/hooks/swap/useTrade';
import { USTrade } from '@app/types/trade';

export function getCurrentTrade(
  selectedTrade:
    | Trade<UNSToken | ModeEther, UNSToken | ModeEther, TradeType.EXACT_INPUT>
    | undefined,
  trades:
    | Trade<UNSToken | ModeEther, UNSToken | ModeEther, TradeType.EXACT_INPUT>[]
    | undefined
) {
  if (selectedTrade) {
    return selectedTrade;
  }

  if (!trades) {
    return undefined;
  }

  let current:
    | Trade<UNSToken | ModeEther, UNSToken | ModeEther, TradeType.EXACT_INPUT>
    | undefined;

  trades.forEach(trade => {
    if (!current) {
      current = trade;
    }

    if (+current.outputAmount.toExact() < +trade.outputAmount.toExact()) {
      current = trade;
    }
  });

  return current;
}

export function useTokensRate(
  from: Token,
  to: Token,
  amount: string,
  isSwapAvailable: boolean,
  trades: USTrade[] | undefined,
  selectedTrade: USTrade | undefined
) {
  const { contract } = useContract(config.contracts.router);

  const [{ value: data, loading: isLoading }, fetchRates] =
    useAsyncFn(async () => {
      if (!isSwapAvailable || !contract || +amount === 0) {
        return;
      }

      if (isEthWethSwap(from, to)) {
        return {
          rate: '1',
          amountOut: amount
        };
      }

      const d = getCurrentTrade(selectedTrade, trades);

      const rate = d ? +d.outputAmount.toExact() / +amount : undefined;

      const amountOut = d ? d.outputAmount.toExact() : undefined;

      if (rate === undefined || isNaN(rate)) {
        return {
          rate: undefined,
          amountOut
        };
      }

      return {
        rate: fromExponential(rate.toString()),
        amountOut
      };
    }, [isSwapAvailable, contract, from, to, amount, selectedTrade, trades]);

  useEffect(() => {
    fetchRates();
  }, [fetchRates]);

  return {
    swapOut: data?.amountOut
      ? fromExponential(data.amountOut)
      : data?.amountOut,
    rate: data?.rate,
    isLoading,
    fetchRates
  };
}
