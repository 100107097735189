import { useContract, useContractRead } from '@thirdweb-dev/react';
import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';

import { config } from '@app/config';
import { isEthWethSwap } from '@app/hooks/swap/helpers';
import { Token } from '@app/types/token';
import { USTrade } from '@app/types/trade';

export function useSwapAvailablity(
  from: Token,
  to: Token,
  trades: USTrade[] | undefined
): {
  isLoading: boolean;
  pairAddress: string | null;
  isSwapAvailable: boolean;
} {
  const { contract } = useContract(config.contracts.factory);

  const { data, isLoading } = useContractRead(contract, 'getPair', [
    from.contractAddress === NATIVE_TOKEN_ADDRESS
      ? config.contracts.weth
      : from.contractAddress,
    to.contractAddress === NATIVE_TOKEN_ADDRESS
      ? config.contracts.weth
      : to.contractAddress
  ]);

  return {
    isLoading,
    pairAddress:
      data && data !== '0x0000000000000000000000000000000000000000'
        ? data
        : null,
    isSwapAvailable:
      isEthWethSwap(from, to) || ((trades && trades.length > 0) ?? false)
  };
}
