import { Box, Spinner, Text, Center } from '@chakra-ui/react';
import React, { FC } from 'react';

import { useTokenBalance } from '@app/hooks/swap/useTokenBalance';

import s from './TokenBalanceInfo.module.scss';
import clsx from 'clsx';
import { getSymbol } from '@app/helpers/symbol';

interface Props {
  address: string;
  isError?: boolean;
}

export const TokenBalanceInfo: FC<Props> = ({ address, isError }) => {
  const { data: selectedTokenBalance, isLoading } = useTokenBalance(address);

  return (
    <Box>
      {isLoading ? (
        <Center height="12px" width="12px">
          <Spinner width="9px" height="9px" color="neutral.400" />
        </Center>
      ) : (
        <Text
          className={clsx(s.value, {
            [s.error]: isError
          })}
        >
          {Number(selectedTokenBalance?.displayValue)}{' '}
          {getSymbol(selectedTokenBalance?.symbol ?? '')}
        </Text>
      )}
    </Box>
  );
};
