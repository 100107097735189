import { StarIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Modal,
  IconButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  VStack
} from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Search } from '@app/components/Search';
import { TokenIcon } from '@app/components/TokenIcon';
import { useInfiniteTokensList } from '@app/hooks/swap/useInfiniteTokensList';
import { usePopularBasesTokens } from '@app/hooks/swap/usePopularBasesTokens';
import { Token } from '@app/types/token';
import { ListLoader } from '@app/screens/pools/components/ListLoader';
import { useChainExplorer } from '@app/hooks/other/useExplorerAddress';

import s from './TokenSelectorModal.module.scss';
import { Icon } from '@app/components/Icon';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  onSelect: (val: Token) => void;
}

export const TokenSelectorModal: FC<Props> = ({
  onClose,
  isOpen,
  onSelect
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const {
    dataFlat,
    hasNextPage,
    fetchNextPage,
    totalLoaded,
    isFetching,
    isLoading
  } = useInfiniteTokensList(
    {
      q: searchTerm
    },
    isOpen
  );

  const { data: popularBases } = usePopularBasesTokens();

  const { explorer } = useChainExplorer();

  function renderLoader() {
    return (
      <>
        <Skeleton
          height="60px"
          width="100%"
          startColor="neutral.700"
          endColor="neutral.800"
          mb="2px"
        />
        <Skeleton
          height="60px"
          width="100%"
          startColor="neutral.700"
          endColor="neutral.800"
          mb="2px"
        />
        <Skeleton
          height="60px"
          width="100%"
          startColor="neutral.700"
          endColor="neutral.800"
          mb="2px"
        />
      </>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setSearchTerm('');
        onClose();
      }}
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent sx={{ maxWidth: ['100vw', '424px'], top: [0, 'unset'] }}>
        <ModalHeader>
          <HStack
            justify="space-between"
            px="40px"
            pb="12px"
            className={s.header}
          >
            <Text className={s.title}>Select token</Text>
            <IconButton
              aria-label="close modal"
              onClick={() => {
                setSearchTerm('');
                onClose();
              }}
            >
              <Icon name="close" />
            </IconButton>
          </HStack>
        </ModalHeader>

        <VStack>
          <div className={s.search}>
            <Search onSearch={val => setSearchTerm(val)} />
          </div>

          {!!popularBases?.length && (
            <Box mt="12px" w="100%">
              <Text
                fontSize="12px"
                lineHeight="18px"
                color="neutral.300"
                mb="12px"
              >
                Popular bases
              </Text>
              <Flex gap="12px" w="100%" flexWrap="wrap">
                {popularBases.map(item => (
                  <Button
                    key={item.contractAddress}
                    p="6px"
                    border="0.5px solid"
                    borderColor="whiteOpacity.6"
                    borderRadius="1000px"
                    bg="transparent"
                    _hover={{ background: 'neutral.700' }}
                    onClick={() => {
                      onSelect(item);
                      setSearchTerm('');
                      onClose();
                    }}
                  >
                    <HStack alignItems="center">
                      <TokenIcon
                        symbol={item.symbol}
                        iconUrl={item.logoUri}
                        gridArea="icon"
                      />
                      <Text
                        fontSize="sm"
                        fontWeight={600}
                        color="orange.200"
                        textTransform="capitalize"
                        gridArea="source"
                        textAlign="left"
                      >
                        {item.symbol}
                      </Text>
                    </HStack>
                  </Button>
                ))}
              </Flex>
            </Box>
          )}
          <Box
            className={s.scrollWrapper}
            id="scrollableDiv"
            overflowY="auto"
            height={['85dvh', 400]}
            mt={4}
            px="40px"
            w="100%"
          >
            <InfiniteScroll
              dataLength={totalLoaded}
              next={fetchNextPage}
              hasMore={hasNextPage ?? false}
              loader={renderLoader()}
              scrollableTarget="scrollableDiv"
            >
              {isLoading ? (
                <ListLoader />
              ) : (
                dataFlat?.map(item => {
                  return (
                    <Grid
                      onClick={() => {
                        onSelect(item);
                        setSearchTerm('');
                        onClose();
                      }}
                      style={{
                        cursor: 'pointer',
                        transition: 'background 0.2s ease'
                      }}
                      key={item.contractAddress}
                      gridTemplateColumns="32px 1fr 24px"
                      gridColumnGap="10px"
                      gridRowGap={0}
                      // borderBottom="1px solid"
                      // borderColor="whiteOpacity.6"
                      alignItems="center"
                      _hover={{ background: 'bg.2' }}
                      w="100%"
                      gridTemplateAreas="'icon source details' 'icon symbol details'"
                      py="12px"
                      px="12px"
                    >
                      <TokenIcon
                        symbol={item.symbol}
                        iconUrl={item.logoUri}
                        gridArea="icon"
                        width="32px"
                        height="32px"
                      />
                      <Text
                        gridArea="source"
                        textAlign="left"
                        className={s.symbol}
                      >
                        {item.symbol}
                      </Text>
                      <HStack gridArea="symbol">
                        <Text textAlign="left" className={s.name}>
                          {item.name}
                        </Text>
                        {/*<Box width="1px" height="20px" bg="neutral.500" />*/}
                        {/*<Link*/}
                        {/*  onClick={e => e.stopPropagation()}*/}
                        {/*  href={`${explorer?.url}/address/${item.contractAddress}`}*/}
                        {/*  target="_blank"*/}
                        {/*>*/}
                        {/*  <Text*/}
                        {/*    fontSize="14px"*/}
                        {/*    color="neutral.400"*/}
                        {/*    textTransform="capitalize"*/}
                        {/*    textAlign="left"*/}
                        {/*    _hover={{ color: 'neutral.100' }}*/}
                        {/*  >*/}
                        {/*    {formatWalletAddress(item.contractAddress)}*/}
                        {/*  </Text>*/}
                        {/*</Link>*/}
                        {/*<CopyButton text={item.contractAddress} />*/}
                      </HStack>
                      {/*{item.whitelisted && (*/}
                      {/*  <StarIcon*/}
                      {/*    gridArea="details"*/}
                      {/*    w="18px"*/}
                      {/*    h="18px"*/}
                      {/*    color="white"*/}
                      {/*  />*/}
                      {/*)}*/}
                    </Grid>
                  );
                })
              )}
            </InfiniteScroll>
          </Box>
        </VStack>
      </ModalContent>
    </Modal>
  );
};
