import { Skeleton, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

import { kFormatter } from '@app/helpers/format';
import { useTokenPriceValue } from '@app/hooks/token/useTokenPriceValue';

import s from './TokenPrice.module.scss';

interface Props {
  tokenAddress: string | undefined;
  value: string;
  symbol: string | undefined;
}

export const TokensPrice: FC<Props> = ({ value, tokenAddress }) => {
  const { value: priceValue, isLoading } = useTokenPriceValue(
    value,
    tokenAddress
  );

  if (isLoading) {
    return (
      <Skeleton
        height="16px"
        width="64px"
        startColor="neutral.700"
        endColor="neutral.800"
        mb="2px"
      />
    );
  }

  return (
    <Text
      color="neutral.400"
      fontSize="12px"
      fontWeight="400"
      lineHeight="18px"
      letterSpacing="-0.18px"
      className={s.value}
    >
      ${priceValue !== undefined ? kFormatter(+priceValue, 2) : priceValue}
    </Text>
  );
};
