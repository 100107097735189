import { HStack, VStack, Text } from '@chakra-ui/react';
import { useAddress, useNetworkMismatch } from '@thirdweb-dev/react';
import React, { FC } from 'react';

import { DebouncedInput } from '@app/components/DebouncedInput';
import { TokenSelector } from '@app/components/Swap/components/TokenSelector';
import { TokensPrice } from '@app/components/Swap/components/TokensPrice';
import { TokenBalanceInfo } from '@app/components/TokenBalanceInfo';
import { Token } from '@app/types/token';

import s from './TokenInput.module.scss';
import { Icon } from '@app/components/Icon';

interface Props {
  label?: string;
  onTokenSelect: (val: Token) => void;
  disabled: boolean;
  onChange?: (val: string) => void;
  values: {
    token: Token | null;
    amount: string;
  };
  showMax?: boolean;
  source: 'from' | 'to';
  readOnlyValue?: string;
  disableSelect?: boolean;
  isBalanceExceeded?: boolean;
}

export const TokenInput: FC<Props> = ({
  onTokenSelect,
  disabled,
  onChange,
  values,
  showMax,
  source,
  readOnlyValue,
  label,
  disableSelect,
  isBalanceExceeded
}) => {
  const address = useAddress();
  const isMismatchedNetwork = useNetworkMismatch();

  return (
    <VStack
      w="100%"
      bg="neutral.1000"
      transition="background 0.2s ease"
      overflow="hidden"
      gap={0}
    >
      {label && <div className={s.caption}>{label}</div>}
      <HStack w="100%" mb="4px" className={s.inputWrapper}>
        <TokenSelector
          source={source}
          onSelect={onTokenSelect}
          disabled={disabled || !!disableSelect}
          selected={values.token}
        />
        <VStack gap={0} width="100%">
          <DebouncedInput
            amount={values.amount}
            disabled={disabled}
            onChange={onChange}
            readOnlyValue={readOnlyValue}
            textAlign="left"
            isError={isBalanceExceeded}
          />
          <div className={s.tokenPriceInfo}>
            <TokensPrice
              value={readOnlyValue ?? values.amount}
              tokenAddress={values.token?.contractAddress}
              symbol={values.token?.symbol}
            />
          </div>
        </VStack>
      </HStack>
      {address && !isMismatchedNetwork && (
        <HStack justify="flex-start" width="100%" alignItems="center" mt="2px">
          <Text className={s.balanceLabel}>Balance:</Text>
          {values.token?.contractAddress && address ? (
            <TokenBalanceInfo
              address={values.token.contractAddress}
              isError={isBalanceExceeded}
            />
          ) : (
            'n/a'
          )}
        </HStack>
      )}
      {isBalanceExceeded && (
        <div className={s.error}>
          <Icon name="error" style={{ width: '16px', height: '16px' }} />{' '}
          <span>Insufficient funds.</span>
        </div>
      )}
    </VStack>
  );
};
