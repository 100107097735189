import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';

import { Token } from '@app/types/token';
import { config } from '@app/config';
import { ModeEnvs } from '@app/constants/chains';

import prt from 'src/assets/images/tokens/prt.png';

export const TOKENS_WHITELIST_TESTNET: Token[] = [
  {
    symbol: 'tCORE',
    contractAddress: NATIVE_TOKEN_ADDRESS,
    decimals: 18,
    logoUri: '',
    name: 'tCORE'
  },
  {
    symbol: 'WtCORE',
    contractAddress: config.contracts.weth ?? '',
    decimals: 18,
    logoUri: '',
    name: 'Wrapped tCORE'
  },
  {
    symbol: 'PRT',
    contractAddress: '0x5ba0431f69f8fe6cd5c039334cada8967e1439a4',
    decimals: 18,
    logoUri: prt.src,
    name: 'Portos'
  },
  {
    symbol: 'DAI',
    contractAddress: '0x3CE486527b3E5c2BE5431bc921a8307FF4011011',
    decimals: 18,
    logoUri: '',
    name: 'Dai'
  },
  {
    symbol: 'USDT',
    contractAddress: '0x9B33324AD47981C96F577b0A054988e1bBD20979',
    decimals: 18,
    logoUri: '',
    name: 'Usdt'
  },
  {
    symbol: 'ARB',
    contractAddress: '0x112cc3DF531D3622398aCA7560D9D900412bA3A4',
    decimals: 18,
    logoUri: '',
    name: 'Arbitrum'
  }
];

export const TOKENS_WHITELIST_MAINNET: Token[] = [
  {
    symbol: 'CORE',
    contractAddress: NATIVE_TOKEN_ADDRESS,
    decimals: 18,
    logoUri: '',
    name: 'CORE'
  },
  {
    symbol: 'WCORE',
    contractAddress: config.contracts.weth ?? '',
    decimals: 18,
    logoUri: '',
    name: 'Wrapped CORE'
  }
];

export const TOKENS_WHITELIST =
  config.modeEnv === ModeEnvs.MAINNET
    ? TOKENS_WHITELIST_MAINNET
    : TOKENS_WHITELIST_TESTNET;

export const POPULAR_BASES = ['ETH', 'WETH'];
