import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Chain } from '@thirdweb-dev/chains';
import {
  useBalance as useContractTokenBalance,
  getContract,
  useChain,
  useAddress
} from '@thirdweb-dev/react';
import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';

import { QueryKeys } from '@app/constants/queryKeys';
import { bigNumberToDecimal } from '@app/helpers/format';

export function useTokenBalance(tokenContractAddress: string) {
  const { data, isLoading } = useContractTokenBalance(tokenContractAddress);

  return {
    data,
    isLoading
  };
}

type TokenBalance = {
  symbol: string;
  value: import('ethers').BigNumber;
  name: string;
  decimals: number;
  displayValue: string;
};

async function fetcher(
  tokenContractAddress: string | undefined,
  chain: Chain | undefined,
  address: string | undefined
): Promise<TokenBalance | undefined> {
  if (
    !chain ||
    !address ||
    !tokenContractAddress ||
    tokenContractAddress === NATIVE_TOKEN_ADDRESS
  ) {
    return undefined;
  }

  const contract = await getContract<'token'>({
    address: tokenContractAddress,
    network: chain
  });

  const balanceRes = await contract.call('balanceOf', [address]);
  const decimalsRes = await contract.call('decimals');

  const balance = Array.isArray(balanceRes) ? balanceRes[0] : balanceRes;
  const decimals = Array.isArray(decimalsRes) ? decimalsRes[0] : decimalsRes;

  return {
    symbol: '',
    value: balance,
    name: '',
    decimals,
    displayValue: (balance && decimals
      ? bigNumberToDecimal(
          Array.isArray(balance) ? balance[0] : balance,
          Array.isArray(decimals) ? decimals[0] : decimals
        )
      : 0
    ).toString()
  };
}

export const useTokenBalanceV2 = (
  tokenContractAddress: string | undefined,
  options: UseQueryOptions<TokenBalance | undefined> = {}
) => {
  const chain = useChain();
  const address = useAddress();

  return useQuery<TokenBalance | undefined>(
    [QueryKeys.TOKEN_BALANCE, { tokenContractAddress }],
    async () => {
      return await fetcher(tokenContractAddress, chain, address);
    },
    {
      enabled: Boolean(tokenContractAddress && chain),
      staleTime: 10000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      ...options
    }
  );
};
