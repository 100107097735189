import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';
import CryptoIcons from 'base64-cryptocurrency-icons';

import { config } from '@app/config';
import { TokenResponseItem } from '@app/hooks/swap/types';
import { Token } from '@app/types/token';

function getCoinIcon(item: TokenResponseItem) {
  if (item.icon_url) {
    return item.icon_url;
  }

  const defaultCoinData = CryptoIcons[item.symbol];

  return defaultCoinData ? defaultCoinData.icon : '';
}

export function getWhitelistedCoinIcon(item: Token) {
  if (item.logoUri) {
    return item.logoUri;
  }

  const defaultCoinData = CryptoIcons[item.symbol];

  return defaultCoinData ? defaultCoinData.icon : '';
}

export function mapTokenResponseItem(item: TokenResponseItem): Token {
  return {
    symbol: item.symbol,
    logoUri: getCoinIcon(item),
    decimals: Number(item.decimals),
    name: item.name,
    contractAddress: item.address
  };
}

export function isEthWethSwap(from: Token, to: Token) {
  return (
    (from.contractAddress === NATIVE_TOKEN_ADDRESS &&
      to.contractAddress === config.contracts.weth) ||
    (to.contractAddress === NATIVE_TOKEN_ADDRESS &&
      from.contractAddress === config.contracts.weth)
  );
}
