import { useInfiniteQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import uniqBy from 'lodash/uniqBy';
import qs from 'qs';

import { QueryKeys } from 'src/constants/queryKeys';

import { TOKENS_WHITELIST } from '@app/constants/tokensWhitelist';
import {
  getWhitelistedCoinIcon,
  mapTokenResponseItem
} from '@app/hooks/swap/helpers';
import { Token } from '@app/types/token';
import { config } from '@app/config';

const LIMIT = 20;

interface QueryParams {
  q: string;
}

let nppFetched;

async function fetcher(
  pageParams: Record<string, string>,
  queryParams: QueryParams
) {
  const q = queryParams.q?.toLowerCase() ?? '';

  return {
    tokens: TOKENS_WHITELIST.filter(
      item =>
        item.name?.toLowerCase().includes(q) ||
        item.symbol?.toLowerCase().includes(q) ||
        item.contractAddress?.toLowerCase().includes(q)
    ),
    next_page_params: undefined
  };

  // const params = queryParams.q
  //   ? {
  //       type: 'ERC-20',
  //       ...queryParams,
  //       ...pageParams
  //     }
  //   : { type: 'ERC-20', ...pageParams };
  //
  // const query = qs.stringify(params, {
  //   encodeValuesOnly: true
  // });

  // const response = await axios.get(`${config.explorerApiUrl}/tokens?${query}`);
  //
  // return {
  //   tokens: uniqBy<Token>(
  //     [...response.data.items.map(mapTokenResponseItem)],
  //     'contractAddress'
  //   ),
  //   next_page_params: response.data.next_page_params
  // };
}

export function useInfiniteTokensList(params: QueryParams, enabled: boolean) {
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch,
    isLoading
  } = useInfiniteQuery<{
    tokens: Token[];
    next_page_params: Record<string, string> | undefined;
  }>(
    [QueryKeys.TOKENS_LIST, { q: params.q }],
    ({ pageParam }) => {
      return fetcher(pageParam, params);
    },
    {
      getNextPageParam: lastPage => {
        return lastPage.next_page_params ?? undefined;
      },
      staleTime: 60000,
      refetchOnWindowFocus: false,
      enabled
    }
  );

  let dataFlat = data?.pages.reduce<Token[]>((res, page) => {
    res.push(...page.tokens);

    return res;
  }, []);

  const q = params.q?.toLowerCase() ?? '';

  const whitelisted = TOKENS_WHITELIST.filter(
    item =>
      item.name?.toLowerCase().includes(q) ||
      item.symbol?.toLowerCase().includes(q) ||
      item.contractAddress?.toLowerCase().includes(q)
  );

  dataFlat = dataFlat
    ? uniqBy(
        [
          ...whitelisted.map(item => ({
            ...item,
            logoUri: getWhitelistedCoinIcon(item),
            whitelisted: true
          })),
          ...dataFlat
        ],
        'contractAddress'
      )
    : undefined;

  const totalLoaded =
    data?.pages.reduce((res, page) => {
      const pageLength = page.tokens.length;

      return res + pageLength;
    }, 0) ?? 0;

  return {
    dataFlat,
    totalLoaded,
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch,
    isLoading
  };
}
