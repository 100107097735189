import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, useDisclosure, Grid, Text } from '@chakra-ui/react';
import React, { FC, useState } from 'react';

import { TokenSelectorModal } from '@app/components/Swap/components/TokenSelectorModal';
import { TokenIcon } from '@app/components/TokenIcon';
import { Token } from '@app/types/token';

import s from './TokenSelector.module.scss';
import { Icon } from '@app/components/Icon';

interface Props {
  selected: Token | null;
  onSelect: (val: Token) => void;
  disabled: boolean;
  source: 'from' | 'to';
}

export const TokenSelector: FC<Props> = ({
  selected,
  disabled,
  onSelect,
  source
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        onClick={onOpen}
        isDisabled={disabled}
        // minWidth={['60px', '80px']}
        width="fit-content"
        h="100%"
        bg="transparent"
        _hover={{
          background: 'transparent'
        }}
        px={0}
        py="4px"
        borderRadius={4}
        sx={{ cursor: disabled ? 'default' : 'pointer' }}
      >
        <Grid
          gridTemplateColumns="32px 1fr"
          gridColumnGap="6px"
          gridRowGap={0}
          w="100%"
          alignItems="center"
        >
          <div className={s.tokenIconWrapper}>
            {selected ? (
              <TokenIcon
                symbol={selected?.symbol}
                iconUrl={selected?.logoUri}
              />
            ) : (
              <div className={s.selectBtn}>
                <Icon name="plus" />
              </div>
            )}
          </div>
          {/*<Text*/}
          {/*  textTransform="capitalize"*/}
          {/*  textAlign="left"*/}
          {/*  overflow="hidden"*/}
          {/*  textOverflow="ellipsis"*/}
          {/*  className={s.tokenSymbol}*/}
          {/*>*/}
          {/*  {selected ? selected.symbol : 'Select token'}*/}
          {/*</Text>*/}
        </Grid>
      </Button>
      <TokenSelectorModal
        onClose={onClose}
        isOpen={isOpen}
        onSelect={onSelect}
      />
    </>
  );
};
