import { RepeatIcon } from '@chakra-ui/icons';
import {
  HStack,
  IconButton,
  Input,
  useDisclosure,
  PopoverBody,
  PopoverTrigger,
  PopoverContent,
  Popover
} from '@chakra-ui/react';
import React, { FC, useState, useEffect } from 'react';

import { useSwapSettings } from '@app/hooks/swap/useSwapSettings';
import { Icon } from '@app/components/Icon';

import s from './HeaderControls.module.scss';

interface Props {
  onRefresh?: () => void;
}

export const HeaderControls: FC<Props> = ({ onRefresh }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [slippage, setSlippage] = useState('');
  const [deadline, setDeadline] = useState('');

  const { settings, updateSettings } = useSwapSettings();

  useEffect(() => {
    if (settings) {
      setSlippage(settings.slippage.toString());
      setDeadline(settings.deadline.toString());
    }
  }, [settings]);

  return (
    <HStack w="100%" justify="flex-end" mb="4px" gap="16px">
      {onRefresh && (
        <IconButton
          aria-label="refresh"
          icon={
            <RepeatIcon
              w="18px"
              h="18px"
              color="neutral.300"
              transition="color 0.2 ease"
              _hover={{ color: 'white', cursor: 'pointer' }}
            />
          }
          onClick={onRefresh}
        />
      )}
      <Popover placement="end-start">
        <PopoverTrigger>
          <IconButton
            aria-label="refresh"
            color="gray.1"
            _hover={{
              color: 'green.1'
            }}
            icon={
              <Icon
                name="settings"
                style={{
                  width: '24px',
                  height: '24px'
                }}
              />
            }
            onClick={onOpen}
          />
        </PopoverTrigger>
        <PopoverContent
          rootProps={{
            zIndex: 1
          }}
        >
          <PopoverBody className={s.panel}>
            <div className={s.block}>
              <div className={s.blockTitle}>MAX slippage</div>
              <HStack gap="8px">
                <Input
                  variant="outlined"
                  bg="bg.2"
                  fontSize="xl"
                  borderRadius="2px"
                  p="2px 12px 2px 0"
                  border="1px solid"
                  borderColor="gray.4"
                  color="gray.0"
                  padding="2px 12px"
                  size="lg"
                  gridArea="input"
                  _focus={{
                    outline: 'none'
                  }}
                  w="90px"
                  type="number"
                  step={0.1}
                  value={slippage}
                  onChange={async e => {
                    setSlippage(e.target.value);
                    await updateSettings({
                      slippage: Number(e.target.value),
                      deadline: Number(deadline)
                    });
                  }}
                  placeholder="0"
                />
                <div className={s.suffix}>%</div>
              </HStack>
            </div>

            <div className={s.divider} />

            <div className={s.block}>
              <div className={s.blockTitle}>Transaction deadline</div>
              <HStack gap="8px">
                <Input
                  variant="outlined"
                  bg="bg.2"
                  fontSize="xl"
                  p="2px 12px 2px 0"
                  border="1px solid"
                  borderRadius="2px"
                  borderColor="gray.4"
                  color="gray.0"
                  padding="2px 12px"
                  size="lg"
                  gridArea="input"
                  _focus={{
                    outline: 'none'
                  }}
                  w="90px"
                  _hover={{ bg: 'neutral.700' }}
                  type="number"
                  value={deadline}
                  onChange={async e => {
                    setDeadline(e.target.value);
                    await updateSettings({
                      deadline: Number(e.target.value),
                      slippage: Number(slippage)
                    });
                  }}
                  placeholder="0"
                />
                <div className={s.suffix}>minutes</div>
              </HStack>
            </div>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </HStack>
  );
};
