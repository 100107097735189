import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { QueryKeys } from '@app/constants/queryKeys';
// import {
//   POPULAR_BASES,
//   TOKENS_WHITELIST
// } from '@app/constants/tokensWhitelist';
// import { getWhitelistedCoinIcon } from '@app/hooks/swap/helpers';
import { Token } from '@app/types/token';

export async function fetcher() {
  return [];
  // return TOKENS_WHITELIST.filter(item =>
  //   POPULAR_BASES.includes(item.symbol)
  // ).map(item => ({
  //   ...item,
  //   logoUri: getWhitelistedCoinIcon(item)
  // }));
}

export const usePopularBasesTokens = (
  options: UseQueryOptions<Token[]> = {}
) => {
  return useQuery<Token[]>(
    [QueryKeys.POPULAR_TOKENS],
    async () => {
      return await fetcher();
    },
    {
      enabled: true,
      staleTime: 30000,
      ...options
    }
  );
};
